html {
  background-color: #eee;
  margin: 0px;
  height: 100%;
}

body {
  background-color: #eee;
  margin: 0px;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: unset;
}
